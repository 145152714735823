import React, { Component } from 'react'
import { WithContext as ReactTags } from 'react-tag-input'
import Tag from './Tag'
import QueryResult from './QueryResult'
import Detail from './Detail'
import './App.css'
import { Auth0Context } from "@auth0/auth0-react"

//NETLIFY; je taky v Detail.js
let ref = window.location.hostname
const baseUrl = (ref === 'localhost') ? 'http://ha.doma:9999/.netlify/functions' : '/.netlify/functions'

const KeyCodes = {
  comma: 188,
  enter: [10, 13],
}

const delimiters = [...KeyCodes.enter, KeyCodes.comma]

class App extends Component {

  static contextType = Auth0Context

  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      suggestions: [],
      queryResult: [],
      detailId: undefined,
      status: ""
    };
    this.handleClear = this.handleClear.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleAddition = this.handleAddition.bind(this)
    this.detail = this.detail.bind(this)
  }

  componentDidMount() {
    fetch(baseUrl + '/tags')
      .then((result) => result.json())
      .then((result) => {
        this.setState({
          suggestions: result,
        })
      })

    setTimeout(() => this.setupjs(), 800)  // terrible workaround due to auth refresh page
  }

  setupjs() {
    const { user } = this.context
    //clear modal Oprava when opened
    let myModal = document.getElementById('staticBackdrop')
    myModal.addEventListener('show.bs.modal', function (event) {
      document.getElementById('text-autor').value = user.name
      document.getElementById('text-email').value = user.email
      document.getElementById('text-autor').classList.add('visually-hidden')
      document.getElementById('text-email').classList.add('visually-hidden')
      document.getElementById('text-soustava').classList.add('visually-hidden')
      document.getElementById('text-oddil').classList.add('visually-hidden')
  if (event.relatedTarget.id === 'obecny') { //called from main screen
        document.getElementById('text-soustava').value = 'obecny'
        document.getElementById('text-oddil').value = 'obecny'
      }
      else { //called from detail
        let soustava = document.getElementById('detail-soustava-id').querySelector('span').innerHTML
        document.getElementById('text-soustava').value = soustava
        document.getElementById('text-oddil').value = event.relatedTarget.id
      }
      // relatedTarget: button#obecny.btn.btn-primary
    })
    //enable switching tabs from javascript, this is needed
    let triggerTabList = [].slice.call(document.querySelectorAll('#pills-tab button'))
    if (triggerTabList.length > 0) {
      triggerTabList.forEach(function (triggerEl) {
        let tabTrigger = new window.bootstrap.Tab(triggerEl)

        triggerEl.addEventListener('click', function (event) {
          event.preventDefault()
          tabTrigger.show()
        })
      })
    }
    this.handleClear()
  }

  handleOprava() {
    var options = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        autor: document.getElementById('text-autor').value,
        email: document.getElementById('text-email').value,
        soustava: document.getElementById('text-soustava').value,
        oddil: document.getElementById('text-oddil').value,
        text: document.getElementById('message-text').value
      })
    }
    fetch(baseUrl + '/oprava', options)
      .then((result) => result.json())
      .then((result) => {
        console.log('result oprava', result)
        document.getElementById('text-soustava').value = ''
        document.getElementById('text-oddil').value = ''
        document.getElementById('message-text').value = ''
        let myModal = document.getElementById('staticBackdrop')
        let inst = window.bootstrap.Modal.getInstance(myModal)
        if (inst !== null)
          inst.hide()  // TODO nefunguje
      })
  }

  handleClear() {
    this.setState({
      tags: [],
    }, () => this.search())
    let triggerEl = document.querySelector('#pills-tab button#pills-seznam-tab')
    let inst = window.bootstrap.Tab.getInstance(triggerEl)
    if (inst !== null)
      inst.show()
  }

  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    }, () => this.search())
    var triggerEl = document.querySelector('#pills-tab button#pills-seznam-tab')
    window.bootstrap.Tab.getInstance(triggerEl).show()
  }

  handleAddition(tag) {
    this.setState(state => ({ tags: [...state.tags, tag] }), () => this.search())
    var triggerEl = document.querySelector('#pills-tab button#pills-seznam-tab')
    window.bootstrap.Tab.getInstance(triggerEl).show()
  }

  search() {
    var options = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(this.state.tags)
    }
    fetch(baseUrl + '/soustava', options)
      .then((result) => result.json())
      .then((result) => {
        this.setState({
          queryResult: result,
          detailId: undefined
        })
      })
  }

  save() {
    var options = {
      method: "PUT"
    }
    fetch(baseUrl + '/soustava', options)
      .then((result) => result.json())
      .then((result) => {
        this.setState({
          status: result,
        })
      })
  }

  detail(id) {
    this.setState({
      detailId: id,
    })
    var triggerEl = document.querySelector('#pills-tab button#pills-detail-tab')
    window.bootstrap.Tab.getInstance(triggerEl).show()
  }

  render() {
    const listTags = this.state.suggestions.map((t) => <Tag key={t.id} tagId={t.id} tagName={t.text} tagClassName={t.className} addFn={this.handleAddition} />);
    const listQueryResults = this.state.queryResult.map((t) => <QueryResult key={t.id} resultId={t.id} tanec={t.tanec} nazev={t.nazev} alts={(t.alts) ? t.alts.join() : ""} datum={t.datum} misto={t.misto} variace={(t.variace) ? t.variace.join('>') : ""} detailFn={this.detail} />);

    const { isAuthenticated, loginWithRedirect, logout } = this.context
    if (!isAuthenticated) {
      return <div className="App mt-5">
        <h1>Katalog tanečních sestav</h1>
        <h5 className="text-danger">Tento web je privátní. Vstup pouze na pozvání.</h5>
        <h5>Je potřeba se nejprve trochu přihlásit</h5>
        <button className="btn btn-primary" id="qsLoginBtn" onClick={() => loginWithRedirect()} type="button">
          Login
        </button>
      </div>
    }

    return <div className="App text-start">

      <div className="clearfix">
        <h3 className="float-start p-2">Katalog tanečních sestav</h3>
        <a href="https://www.csts.cz/cs/Files/GetFile/2013" rel="noreferrer" target="_blank"><i className="fas fa-atlas"></i></a>
        <button className="float-end btn btn-secondary m-1 me-2 visually-hidden" type="button" onClick={() => logout({ returnTo: window.location.origin })}>
          Logout
        </button>
        <button className="float-end btn btn-primary m-1 me-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTags" aria-expanded="false" aria-controls="collapseTags">
          Variace
        </button>
        <button className="float-end btn btn-primary m-1" type="button" onClick={this.handleClear}>
          Vyčistit
        </button>
        <span id="obecny" data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="ms-2 text-primary"><i className="fas fa-edit"></i></span>

      </div>

      <div className="collapse" id="collapseTags">
        {listTags}
      </div>

      <div className="m-2">
        <ReactTags tags={this.state.tags}
          suggestions={this.state.suggestions}
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          allowDragDrop={false}
          allowUnique
          autocomplete
          autofocus
          delimiters={delimiters}
          inputFieldPosition="bottom"
          placeholder={'Hledat podle variací'}
          classNames={{
            tags: "card p-2 bg-light",
            tagInput: "d-inline",
            tagInputField: "m-1",
            selected: "",
            tag: "rounded-pill text-light bg-success p-2",
            remove: "rounded-circle bg-light border-light",
            suggestions: "list-group list-group-item",
            activeSuggestion: "list-group-item active p-0 bg-secondary",
          }}
        />
      </div>

      <div className="m-2">
        <nav>
          <div className="nav nav-tabs mb-3 mt-3" id="pills-tab" role="tablist">
            <button className="nav-link active" id="pills-seznam-tab" data-bs-toggle="pill" data-bs-target="#pills-seznam" type="button" role="tab" aria-controls="pills-seznam" aria-selected="true">Seznam nalezených sestav</button>
            <button className="nav-link" id="pills-detail-tab" data-bs-toggle="pill" data-bs-target="#pills-detail" type="button" role="tab" aria-controls="pills-detail" aria-selected="false">Detail sestavy</button>
          </div>
        </nav>

        <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-seznam" role="tabpanel" aria-labelledby="pills-seznam-tab">
            <div className="query-result-list accordion" id="accordionSestavy">
              {listQueryResults}
            </div>
          </div>

          <div className="tab-pane fade" id="pills-detail" role="tabpanel" aria-labelledby="pills-detail-tab">
            <Detail detailId={this.state.detailId} />
          </div>
        </div>
      </div>

      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="true" data-bs-keyboard="true" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">Úprava údajů</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <input type="text" className="form-control" id="text-autor" />
                  <input type="text" className="form-control" id="text-email" />
                  <input type="text" className="form-control" id="text-soustava" />
                  <input type="text" className="form-control" id="text-oddil" />
                  <label htmlFor="message-text" className="col-form-label">Navrhuji změnit:</label>
                  <textarea className="form-control" id="message-text"></textarea>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Zrušit</button>
              <button type="button" className="btn btn-primary" onClick={this.handleOprava}>Odeslat</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default App
