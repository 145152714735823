import React, { Component } from 'react'
import './Detail.css'

let ref = window.location.hostname
const baseUrl = (ref === 'localhost') ? 'http://ha.doma:9999/.netlify/functions' : '/.netlify/functions'

class Detail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.detailId !== this.props.detailId) {
      this.updateAndNotify();
    }
  }

  updateAndNotify = () => {
    fetch(baseUrl + '/soustava?id=' + this.props.detailId)
      .then((result) => result.json())
      .then((result) => {
        this.setState({
          data: result,
        })
      })
  }

  render() {
    // const listTags = this.state.suggestions.map((t) => <Tag key={t.id} tagId={t.id} tagName={t.text} tagClassName={t.className} addFn={this.handleAddition}/>);


    let obrazky
    if (this.state.data.obrazky) {
      obrazky = this.state.data.obrazky.map((o, i) => <div key={o} className={(i === 0) ? "carousel-item active" : "carousel-item"} data-bs-interval="200000"><img src={'/images/' + this.state.data.id + '/' + o} className="d-block w-100" alt="obrazek" /></div>)
    }

    let carousel
    if (this.state.data.obrazky) carousel = <div id="carouselFade" className="carousel slide carousel-dark carousel-fade" data-bs-ride="carousel">
      <div className="carousel-inner">
        {obrazky}
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselFade" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Předchozí</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselFade" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Další</span>
      </button>
    </div>


    let youtubes = []
    if (this.state.data.youtubes) {
      youtubes = this.state.data.youtubes.map((o) => <ul key={o.url} className="list-group"><li className="list-group-item"><a href={o.url} rel="noreferrer" target="_blank">{o.popis}</a></li></ul>)
    }

    let variace = []
    if (this.state.data.variaceDocs) {
      variace = this.state.data.variaceDocs.map((o, idx) => <tr key={'v' + idx}><th scope="row">{idx + 1}</th><td>{o.text}</td><td>{o.rytm}</td></tr>)
    }

    return <div className="">
      <div className="card mb-4">
        <div className="card-header">
          <h5>{this.state.data.nazev} ({this.state.data.tanec})</h5>
        </div>

        <div className="fw-lighter text-secondary clearfix" id='detail-soustava-id'>id: <span>{this.state.data.id}</span></div>
        <div className="text-secondary clearfix">Alternativní názvy: <span className="fst-italic text-secondary">{(this.state.data.alts) ? this.state.data.alts.join() : ""}</span></div>
        <div className="clearfix mt-2">
          <span className="pe-4"><i className="far fa-calendar-alt pe-2"></i>{this.state.data.datum}</span>
          <span className="p-4"><i className="fas fa-map-marked-alt pe-2"></i>{this.state.data.misto}</span>
        </div>
      </div>

      <div className="card mb-4">
        <div className="card-header">
          <h5 className="float-start">Variace sestavy</h5>
          <button type="button" id="popis" className="btn btn-secondary p-1 ps-2 pe-2 float-end" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            <i className="fas fa-edit me-2"></i>Oprava
          </button>
        </div>
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Variace</th>
              <th scope="col">Rytmizace</th>
            </tr>
          </thead>
          <tbody>
            {variace}
          </tbody>
        </table>
      </div>

      <div className="card mb-4">
        <div className="card-header">
          <h5 className="float-start"><i className="fas fa-pencil-alt"></i>Poznámky</h5>
          <button type="button" id="poznamky" className="btn btn-secondary p-1 ps-2 pe-2 float-end" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            <i className="fas fa-edit me-2"></i>Oprava
          </button>
        </div>
        <div><pre>{this.state.data.popis}</pre></div>
      </div>

      <div className="card mb-4">
        <div className="card-header">
          <h5 className="float-start"><i className="fab fa-youtube"></i>Odkazy na videa</h5>
          <button type="button" id="videa" className="btn btn-secondary p-1 ps-2 pe-2 float-end" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            <i className="fas fa-edit me-2"></i>Oprava
          </button>
        </div>
        {youtubes}
      </div>

      <div className="card mb-4">
        <div className="card-header">
          <h5 className="float-start"><i className="fas fa-draw-polygon"></i>Nákresy</h5>
          <button type="button" id="obrazky" className="btn btn-secondary p-1 ps-2 pe-2 float-end" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            <i className="fas fa-edit me-2"></i>Oprava
          </button>
        </div>
        {carousel}
      </div>

    </div>
  }
}

export default Detail
