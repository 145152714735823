import React, { Component } from 'react'
import './QueryResult.css'

class QueryResult extends Component {


  render() {

    var variace = this.props.variace.replaceAll('>', ' - ')

    return <div className="accordion-item">
      <h2 className="accordion-header">
        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#" + this.props.resultId} aria-expanded="false" aria-controls={this.props.resultId}>
          {this.props.nazev} ({this.props.tanec}) <span className="fw-lighter ms-4"><small>{this.props.datum}</small></span>
        </button>
      </h2>
      <div id={this.props.resultId} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionSestavy">
        <div className="accordion-body">
          <div className="fst-italic text-secondary clearfix">{this.props.alts}</div>
          <div className="clearfix mt-2">
            <span className="pe-4"><i className="far fa-calendar-alt pe-2"></i>{this.props.datum}</span>
            <span className="p-4"><i className="fas fa-map-marked-alt pe-2"></i>{this.props.misto}</span>
            <button onClick={() => this.props.detailFn(this.props.resultId)} type="button" className="btn btn-primary p-1 ps-3 pe-3 float-end">Detail</button>
          </div>
          <div className="">{variace}</div>
        </div>
      </div>
    </div>
  }
}

export default QueryResult
