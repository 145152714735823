import React, {Component} from 'react'
import './Tag.css'

class Tag extends Component {

  render() {
    return <button onClick={() => this.props.addFn({id:this.props.tagId, text:this.props.tagName})} type="button" className={"Tag btn rounded-pill btn-primary " + this.props.tagClassName}>{this.props.tagName}</button>
  }
}

export default Tag
